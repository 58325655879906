<template lang="pug">
div
  v-toolbar.white(flat dense)
    v-toolbar-title.font-weight-black.title
      slot(name="title")
    v-spacer
    v-btn.black.ml-5(icon @click="add" dark small)
      v-icon add
    v-layout(row slot="extension")
      slot(name="extension")
  v-list(subheader)
    v-list-tile.list-item(
        v-for='item in items'
        :key='item._id'
        @click="select(item)"
      )
      v-list-tile-content
        v-list-tile-title
          slot(:item="item")
</template>
<script>
export default {
  props: {
    items: { type: Array }
  },
  methods: {
    select (item) { this.$emit('select', item) },
    add () { this.$emit('add') }
  }
}
</script>
