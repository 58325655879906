<template lang="pug">
div.pa-0
  v-toolbar(
    app
    dark
    flat
    :class="toolbarClass"
  )
    slot(name="controls")
    v-spacer
    v-toolbar-title
      h2.title.font-weight-black.text-lowercase
        span.font-weight-bold.pink--text
          slot(name="title-prefix")
        span.font-weight-medium.black--text
          slot(name="title")
  div
    slot(name="form")
</template>
<script>
export default {
  props: {
    toolbarClass: {
      type: String,
      default: 'white'
    }
  },
  data: () => ({
    detail: true, 
    tabs: 0
  }),
  methods: {
    setTabs(tab) {
      this.tabs = tab
    }
  }
}
</script>
