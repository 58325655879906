<template lang="pug">
div
  ListCollectionSimple(
    :items="itemsSorted"
    @select="select"
    @add="add"
  )
    template(slot="extension")
      v-layout.caption(row)
        v-flex(xs3) name
        v-flex(xs2) code
        v-flex(xs2) type
        v-flex.text-xs-center(xs1) use
        v-flex(xs2).text-xs-center set cost
        v-flex(xs1).text-xs-center set time
        v-flex(xs1).text-xs-center #
    template(slot-scope="{ item }")
      v-layout.px-2(row)
        v-flex(xs3) {{item.name}}
        v-flex(xs2) {{item.code}}
        v-flex(xs2) {{item.component.type.name}}
        v-flex.text-xs-center(xs1) {{item.component.useCost}}
        v-flex(xs2).text-xs-center {{item.component.setup.cost}}
        v-flex(xs1).text-xs-center {{item.component.setup.time}}
        v-flex(xs1).text-xs-center {{item.quantity}}

    template(slot="title")
      | decoration codes
  DetailSimple(
    :detail="detail"
    @save="save"
    @remove="remove"
    @cancel="cancel"
  )
</template>

<script>
import { DecorationCode } from '@/models'
import { mapFields } from 'vuex-map-fields'
import ListCollectionSimple from '@/components/list-collection-simple'
import DetailSimple from './detail'

export default {
  components: { ListCollectionSimple, DetailSimple },
  data: () => ({
    detail: false,
  }),
  computed: {
    ...mapFields('decorations', {
      decoration: 'item',
      items: 'item.codes',
      input: 'input.code'
    }),
    itemsSorted () {
      return this.sortByString(this.items, 'name')
    }
  },
  methods: {
    add () {
      this.input = new DecorationCode()
      this.detail = true
    },
    select (item) {
      this.input = new DecorationCode(item)
      this.detail = true
    },
    save () {
      const item = this.input
      let items = this.items.map(a => { return a })
      let index = items.findIndex(el => {
        return item._id === el._id
      })
      if (index !== -1) {
        items.splice(index, 1, item)
      } else {
        items.push(item)
      }
      this.items = items
      this.$store.dispatch('decorations/updateOne', {
        data: this.decoration
      })
      this.cancel()
    },
    cancel () {this.detail = false },
    remove () {
      const item = this.input
      let items = this.items.map(a => { return a })
      let index = items.findIndex(el => {
        return item._id === el._id
      })
      items.splice(index, 1)
      this.items = items
      this.$store.dispatch('decorations/updateOne', {
        data: this.decoration
      })
      this.cancel()
    }
  }
}
</script>
