<template lang="pug">
v-layout(row)
  v-flex(xs4)
    v-select(
      label="category"
      v-model="decorationCategory"
      :items='categories'
      item-text='name'
      item-value='_id'
    )
    v-text-field(
      label="name"
      v-model="name"
    )
    v-text-field(
      label="quantityPerHour"
      v-model="quantityPerHour"
    )
    v-textarea(
      label="description"
      v-model="description"
    )
    v-btn(@click='clearcodes') clear codes
  v-flex(xs8 pl-3)
    DecorationCodes
      
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DecorationCodes from './decoration-codes'

export default {
  components: { DecorationCodes },
  computed: {
    ...mapFields('decorations', {
      item: 'item',
      name: 'item.name',
      description: 'item.description',
      quantityPerHour: 'item.quantityPerHour',
      decorationCategory: 'item.decorationCategory',
    }),
    ...mapFields('decorationCategories', {
      categories: 'items',
    }),
  },
  methods: {
    clearcodes() {
      this.item.codes = []
    }
  }
}
</script>
