<template lang="pug">
DetailFullPage(v-if="loaded")
  template(slot="title-prefix")
    | decoration: 
  template(slot="title")
    | {{ item.name }}
  template(slot="form")
    v-container(fluid)
      DetailForm
  template(slot="controls")
    v-btn(@click="save") back
    v-btn.pink(@click="remove") delete
</template>

<script>
import DetailFullPage from '@/components/base/detail-full-page'
import DetailForm from './form'

export default {
  components: {
    DetailFullPage,
    DetailForm,
  },
  data: () => ({ loaded: false }),
  computed: {
    item () { return this.$store.getters['decorations/item'] }
  },
  methods: {
    async save () {
      await this.$store.dispatch('decorations/save', this.item)
      this.cancel()
    },
    async remove () {
      await this.$store.dispatch('decorations/remove', this.item._id)
      this.cancel()
    },
    cancel () {
      this.$router.go(-1)
    }
  },
  async created () {
    const get = await this.$store.dispatch('decorations/getOne', this.$route.params.id)
    this.$store.commit('decorations/item', get)
    this.loaded = true
  }
}
</script>
