<template lang="pug">
DetailSmall(:detail="detail")
  template(slot="title")
    | address detail.
  template(slot="form")
    v-select(
      v-model="code"
      :items="decorationCodes"
      item-text="name"
      return-object
      label="decoration code to add"
    )
    v-text-field(
      label="quantity"
      v-model.number="quantity"
    )
    
  template(slot="controls")
    DetailControls(
      @save="save"
      @cancel="cancel"
      @remove="remove"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import DetailSmall from '@/components/base/detail-small-modal'
import DetailControls from '@/components/base/detail-controls-simple'

export default {
  components: {
    DetailControls,
    DetailSmall
  },
  props: ['detail'],
  computed: {
    ...mapFields('decorations', {
      code: 'input.code',
      quantity: 'input.code.quantity'
    }),
    decorationCodes () {
      return this.$store.getters['decorationCodes/items']
    },
  },
  methods: {
    save () {
      this.$emit('save')
      this.cancel()
    },
    cancel () { this.$emit('cancel') },
    remove () {
      this.$emit('remove')
      this.cancel()
    }
  }
}
</script>
